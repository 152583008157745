<template>
  <div>
    <iframe
      :src='frame'
      width='100%'
      height='1200px'
      frameborder='0'
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import po from '@/api/DrugManagement/po'
import { formatPrice, sumdatetime, sumdate } from '@/plugins/filters'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const frame = ref('')
    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'


    po.poGetPrint({
      purchase_id_pri: route.value.query.id,
      lang: i18n.locale,
    }).then(res => {
      savePDF(res.data)
    })


    const headers = [
      i18n.t('code'),
      i18n.t('list'),
      i18n.t('tax'),
      i18n.t('amount'),
      i18n.t('unit'),
      i18n.t('cost_price'),
      i18n.t('total_price'),
    ]
    const generateData = data => {
      const result = []
      data.forEach(item => {
        result.push([
          item.purchaseorder_drug_id,
          item.purchaseorder_drug_name,
          `${item.purchaseorder_vat}%`,
          formatPrice(item.purchaseorder_amount),
          item.purchaseorder_drug_unit,
          formatPrice(item.purchaseorder_cost),
          formatPrice(item.purchaseorder_total),
        ])
      })

      return result
    }
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: 'ใบเบิกยา / อุปกรณ์',
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      const spacer = 5
      let total = 10

      const footer = [
        {
          content: 'รวม',
          colSpan: 6,
          styles: {
            halign: 'center',
          },
        },
        {
          content: formatPrice(data.purchase_price) || 0,
          colSpan: 1,
          styles: {
            halign: 'right',
          },
        },
        // formatPrice(data.transfer_total),
      ]
      //-------------- ข้อมูลร้านค้า --------------//
      doc.setFontSize(fontTitle.value)
      doc.setFillColor('#212121')
      doc.text(`${i18n.t('purchase_shop')}`, 5, total)
      total += spacer
      doc.text(`${data.shop_name} ${data.shop_nature}`, 5, total)
      doc.setFont('TH-Niramit', 'normal')
      total += spacer
      doc.text(`${data.shop_address} ${data.shop_district}`, 5, total)
      total += spacer
      doc.setFontSize(16)
      doc.setFont('TH-Niramit', 'bold')
      doc.text(i18n.t('purchase'), 205, total - 5, null, null, 'right')
      doc.text(`${i18n.t('a_number')} ${data.purchase_id}`, 205, total + 2, null, null, 'right')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontTitle.value)
      doc.text(`${data.shop_amphoe} ${data.shop_province} ${data.shop_zipcode}`, 5, total)
      total += spacer
      doc.text(`เบอร์โทรศัพท์ ${data.shop_tel} ${i18n.t('email')} ${data.shop_email}`, 5, total)
      total += spacer
      doc.text(`${i18n.t('numberLicenses')} ${data.shop_license} ${i18n.t('tax_id')} ${data.shop_tax}`, 5, total)
      doc.setFontSize(10)
      doc.text(`${i18n.t('print_date')} ${sumdate(data.purchase_date_print)}`, 205, total + 2, null, null, 'right')
      total += spacer
      doc.setLineWidth(0.2)
      doc.setDrawColor('#1E88E5')
      doc.line(61, 39, 205, 39)
      doc.setDrawColor('#212121')
      doc.line(4, 39, 60, 39)
      //-------------- ข้อมูลผู้จำหน่าย --------------//
      total += spacer
      doc.setFontSize(fontTitle.value)
      doc.setFillColor('#212121')
      doc.setFont('TH-Niramit', 'bold')
      doc.text(`${i18n.t('dealer')}`, 5, total)
      total += spacer
      doc.text(`${data.purchase_from_name || ''}`, 5, total)
      doc.setFont('TH-Niramit', 'normal')
      total += spacer
      doc.text(`${data.purchase_from_address || ''} ${data.purchase_from_district || ''}`, 5, total)
      total += spacer
      doc.setFontSize(fontTitle.value)
      doc.text(`${data.purchase_from_amphoe || ''} ${data.purchase_from_province || ''} ${data.purchase_from_zipcode || ''}`, 5, total)
      total += spacer
      doc.text(`เบอร์โทรศัพท์ ${data.purchase_from_tel || ''} ${i18n.t('email')} ${data.purchase_from_email || ''}`, 5, total)
      total += spacer
      doc.text(`${i18n.t('tax_id')} ${data.purchase_from_tax || ''}`, 5, total)
      total += spacer
      doc.setLineWidth(0.2)
      doc.setDrawColor('#1E88E5')
      doc.line(61, 39, 205, 39)
      doc.setDrawColor('#212121')
      doc.line(4, 39, 60, 39)
      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text(`${i18n.t('list')}`, 5, total)
      doc.setFont('TH-Niramit', 'normal')
      doc.text(`${i18n.t('date')} : ${sumdatetime(data.purchase_date)}`, 205, total, null, null, 'right')
      total += spacer - 4

      //-------------- ตาราง --------------//

      doc.autoTable({
        theme: 'grid',
        styles: {
          font: 'TH-Niramit',
          fontStyle: 'normal',
          textColor: '#212121',
          fontSize: fontBody.value,
          halign: 'left',
        },
        showFoot: 'lastPage',
        headStyles: {
          textColor: '#fff',
          fillColor: '#1E88E5',
          fontStyle: 'bold',
          lineWidth: 0.1,
          fontSize: fontTitle.value,
          halign: 'center',
        },
        footStyles: {
          textColor: '#212121',
          fillColor: '#fff',
          fontStyle: 'bold',
          lineWidth: 0.1,
          fontSize: fontBody.value,
          halign: 'right',
        },
        columnStyles: {
          0: { halign: 'center', cellWidth: 18 },
          1: { halign: 'left', fillColor: '#F5F5F5' },
          2: { halign: 'center', cellWidth: 15 },
          3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 20 },
          4: { halign: 'right', cellWidth: 25 },
          5: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 25 },
          6: { halign: 'right', cellWidth: 20 },
        },
        startY: total,
        margin: { left: 5, right: 5, bottom: 10 },
        head: [headers],
        body: generateData(data.purchaseorder),
        foot: [footer],

        didDrawPage(datax) {
          total = datax.cursor.y
        },
      })
      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text(i18n.t('note'), 5, total)
      doc.setFont('TH-Niramit', 'normal')
      doc.text(data.purchase_comment || '', 28, total)
      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text(i18n.t('status'), 5, total)
      doc.text(`(${i18n.t('userConfirm')})`, 160, total, null, null, 'center')
      doc.setFont('TH-Niramit', 'normal')
      if (data.purchase_status_id == 1) {
        doc.text(i18n.t('saveTheDraft'), 28, total)
      } else if (data.purchase_status_id == 2) {
        doc.text(i18n.t('wait_for_payment'), 28, total)
      } else if (data.purchase_status_id == 3) {
        doc.text(i18n.t('waitWarehouse'), 28, total)
      } else if (data.purchase_status_id == 4) {
        doc.text(i18n.t('success'), 28, total)
      } else {
        doc.text(i18n.t('cancel'), 28, total)
      }
      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text(i18n.t('user_is_list'), 5, total)
      doc.setFont('TH-Niramit', 'normal')
      if (data.purchase_status_id == 1) {
        doc.text(data.user_fullname, 28, total)
      } else if (data.purchase_status_id == 2) {
        doc.text(data.user_fullname_confirm, 28, total)
      } else if (data.purchase_status_id == 3) {
        doc.text(data.user_fullname_pay, 28, total)
      } else if (data.purchase_status_id == 4) {
        doc.text(data.user_fullname_import, 28, total)
      } else {
        doc.text(data.user_fullname_cancel, 28, total)
      }
      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text(i18n.t('date'), 5, total)

      doc.setFont('TH-Niramit', 'normal')
      if (data.purchase_status_id == 1) {
        doc.text(sumdatetime(data.purchase_date), 28, total)
      } else if (data.purchase_status_id == 2) {
        doc.text(sumdatetime(data.purchase_date_confirm), 28, total)
      } else if (data.purchase_status_id == 3) {
        doc.text(sumdatetime(data.purchase_date_pay), 28, total)
      } else if (data.purchase_status_id == 4) {
        doc.text(sumdatetime(data.purchase_date_import), 28, total)
      } else {
        doc.text(sumdatetime(data.purchase_date_cancel), 28, total)
      }
      doc.text(`.................................................`, 160, total, null, null, 'center')
      total += spacer
      total += spacer
      doc.text(`(............/............/............)`, 160, total, null, null, 'center')
      // doc.text('....................................................................', 52.5, total, null, null, 'center')
      // doc.text('....................................................................', 157.5, total, null, null, 'center')
      // total += spacer

      // total += spacer
      //
      // doc.text(`${i18n.t('staff')}`, 52.5, total, null, null, 'center')
      // doc.text(`${i18n.t('pathfinder')}`, 157.5, total, null, null, 'center')
      // total += spacer
      // doc.text(`${i18n.t('date')}............./............./.............`, 52.5, total, null, null, 'center')
      // doc.text(`${i18n.t('date')}............./............./.............`, 157.5, total, null, null, 'center')

      frame.value = doc.output('datauristring', { filename: 'test.pdf' })
    }

    return { savePDF, frame }
  },
}
</script>
